import React, { lazy } from "react";
import { ProductsCanonical } from "../buyer-page";

import {
  NewProducts,
  Overview,
  HomeDashboard,
  Orders,
  Settings,
  Payments,
  Customers,
  AddProduct,
  SingleProduct,
  CompleteSetup,
  UpgradePlan,
  VerifyPremiumPayment,
  SocialPages,
  Plan,
  SocialProducts,
  Profile,
  Discounts,
  StoreSetting,
  Invoices,
  CreateInvoice,
  EditInvoice,
  OrderDetail,
  InvoiceDetail,
  Wallet,
} from "pages/App";

import Coupon from "pages/App/Coupon";
import MyPurchases from "pages/App/MyPurchases";
import MyPurchaseDetail from "pages/App/MyPurchaseDetail";

import Messaging from "pages/App/Messaging";


const Logout = lazy(() => import("pages/Logout"));
const VerifyEmail = lazy(() => import("pages/VerifyEmail"));
const AmbassadorHomePage = lazy(() => import("pages/AmbassadorHomePage"));

const IncomingPayment = lazy(() =>
  import("pages/VendorPayment/IncomingPayment")
);
const TrackPayment = lazy(() => import("pages/VendorPayment/TrackPayment"));
const PaymentConfirmation = lazy(() =>
  import("pages/VendorPayment/PaymentConfirmation")
);

const WelcomePage = lazy(() => import("pages/WelcomePage"));
const ProductPolicy = lazy(() => import("pages/ProductPolicy"));
const AmlPolicy = lazy(() => import("pages/AmlPolicy"));
const TermsAndConditionPage = lazy(() => import("pages/TermsAndConditionPage"));

const ThirdParty = lazy(() => import("pages/ThirdParty"));

// New Dashboard starts here.
const Products = lazy(() => import("pages/DashboardPages/Products"));
const Support = lazy(() => import("pages/DashboardPages/Support"));
const Company = lazy(() => import("pages/DashboardPages/Company"));
const Pricing = lazy(() => import("pages/DashboardPages/Pricing"));
// const CampusAmbassedor = lazy(() => import("pages/CampusAmbassadorPage"));

const Escrow = lazy(() => import("pages/DashboardPages/Escrow"));
const newDashboardLogin = lazy(() => import("pages/DashboardPages/Login"));
const newDashboardRegister = lazy(() =>
  import("pages/DashboardPages/Register")
);
const newDashboardForgetPassword = lazy(() =>
  import("pages/DashboardPages/ForgotPassword")
);
const newDashboardConfirmPassword = lazy(() =>
  import("pages/DashboardPages/ConfirmPassword")
);
const newDashboardResetPassowrd = lazy(() =>
  import("pages/DashboardPages/ResetPassword")
);

const LandingPage = lazy(() => import("pages/LandingPage"));
const RefererPage = lazy(() => import("pages/RefererPage"));
const StoreSetupPage = lazy(() => import("pages/StoreSetupPage"));

const InitiateAuth = lazy(() => import("pages/VendorPayment/InitiateAuth"));
const Dashboard = lazy(() => import("pages/App/Dashboard"));

const routes = [
  // new Dashboard starts here

  {
    path: "/dashboard",
    //exact: true,
    component: Dashboard,
    isProtected: false,
  },

  {
    path: "/",
    exact: true,
    component: newDashboardLogin,
    isProtected: false,
  },
  {
    path: "/register",
    exact: true,
    component: newDashboardRegister,
    isProtected: false,
  },
  {
    path: "/store-setup",
    exact: true,
    component: StoreSetupPage,
    isProtected: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    component: newDashboardForgetPassword,
    isProtected: false,
  },
  {
    path: "/verify-email/:verifyToken",
    exact: true,
    component: VerifyEmail,
    isProtected: false,
  },
  {
    path: "/confirm-password",
    exact: true,
    component: newDashboardConfirmPassword,
    isProtected: false,
  },
  {
    path: "/reset_password/:token",
    exact: true,
    component: newDashboardResetPassowrd,
    isProtected: false,
  },

  {
    path: "/welcome",
    exact: true,
    component: WelcomePage,
    isProtected: false,
  },

  {
    path: "/referral",
    exact: true,
    component: RefererPage,
    isProtected: false,
  },

  {
    path: "/vendor/incoming-payment",
    // exact: false,
    component: IncomingPayment,
    isProtected: false,
  },
  {
    path: "/vendor/track-payment",
    // exact: false,
    component: TrackPayment,
    isProtected: false,
  },
  {
    path: "/vendor/payment-confirmation",
    // exact: false,
    component: PaymentConfirmation,
    isProtected: false,
  },

  {
    path: "/vendor/track-payment",
    // exact: false,
    component: TrackPayment,
    isProtected: false,
  },
  {
    path: "/vendor/payment-confirmation",
    // exact: false,
    component: PaymentConfirmation,
    isProtected: false,
  },
  {
    path: "/vendor/initiate-auth",
    exact: true,
    component: InitiateAuth,
    isProtected: false,
  },
  {
    path: "/ambassador",
    // exact: false,
    component: AmbassadorHomePage,
    isProtected: true,
  },

  {
    path: "/confirm-password",
    exact: true,
    component: newDashboardConfirmPassword,
    isProtected: false,
  },
  {
    path: "/reset_password/:token",
    exact: true,
    component: newDashboardResetPassowrd,
    isProtected: false,
  },

  {
    path: "/logout",
    exact: true,
    component: Logout,
    isProtected: false,
  },

  {
    path: "/ambassador",
    // exact: false,
    component: AmbassadorHomePage,
    isProtected: true,
  },

  {
    path: "/api/thirdParty/orderTranx/getOrderTranx",
    exact: false,
    component: ThirdParty,
    isProtected: false,
  },
  {
    path: "/products-policy",
    exact: true,
    component: ProductPolicy,
    isProtected: false,
  },
  {
    path: "/aml-cft-policy",
    exact: true,
    component: AmlPolicy,
    isProtected: false,
  },

  {
    path: "/allProducts",
    exact: true,
    component: ProductsCanonical,
    isProtected: false,
  },
];

export default routes;

export const dashboardRoutes = [
  {
    path: "/dashboard/coupons",
    exact: true,
    component: Coupon,
    isPremium: true,
  },
  {
    path: "/dashboard/discounts",
    exact: true,
    component: Discounts,
    isPremium: true,
  },
  {
    path: "/dashboard/overview",
    exact: true,
    component: Overview,
    isPremium: false,
  },
  {
    path: "/dashboard/home",
    exact: true,
    component: HomeDashboard,
    isPremium: false,
  },
  // {
  //   path: "/dashboard/wallet",
  //   exact: true,
  //   component: Wallet,
  //   isPremium: false,
  // },
  {
    path: "/dashboard/payments",
    exact: true,
    component: Payments,
    isPremium: false,
  },
  {
    path: "/dashboard/products",
    exact: true,
    component: NewProducts,
    isPremium: false,
  },
  {
    path: "/dashboard/plan",
    exact: true,
    component: Plan,
    isPremium: false,
  },
  {
    path: "/dashboard/profile",
    exact: true,
    component: Profile,
    isPremium: false,
  },
  {
    path: "/dashboard/orders",
    exact: true,
    component: Orders,
    isPremium: false,
  },

  {
    path: "/dashboard/orders/:orderRef",
    exact: true,
    component: OrderDetail,
    isPremium: false,
  },
  {
    path: "/dashboard/mypurchases",
    exact: true,
    component: MyPurchases,
    isPremium: false,
  },
  {
    path: "/dashboard/mypurchases/:orderRef",
    exact: true,
    component: MyPurchaseDetail,
    isPremium: false,
  },

  {
    path: "/dashboard/customers",
    exact: true,
    component: Customers,
    isPremium: false,
  },
  {
    path: "/dashboard/invoices",
    exact: true,
    isPremium: true,
    component: Invoices,
  },
  {
    path: "/dashboard/invoices/new",
    exact: true,
    component: CreateInvoice,
    isPremium: true,
  },
  {
    path: "/dashboard/invoices/:invoiceID",
    exact: true,
    component: EditInvoice,
    isPremium: true,
  },
  {

    path: "/dashboard/messaging",
    exact: true,
    component: Messaging,
    isPremium: false,
  },
  {
    path: "/dashboard/invoices/detail/:invoiceID",
    exact: true,
    component: InvoiceDetail,
    isPremium: true,

  },
  {
    path: "/dashboard/settings",
    exact: true,
    component: Settings,
    isPremium: false,
  },
  {
    path: "/dashboard/store-setting",
    exact: true,
    component: StoreSetting,
  },
  {
    path: "/dashboard/social-pages",
    exact: true,
    component: SocialPages,
    isPremium: false,
  },
  {
    path: "/dashboard/social-products",
    exact: true,
    component: SocialProducts,
    isPremium: false,
  },
  {
    path: "/dashboard/complete-setup",
    exact: true,
    component: CompleteSetup,
    isPremium: false,
  },
  {
    path: "/dashboard/products/new",
    exact: true,
    component: AddProduct,
    isPremium: false,
  },
  {
    path: "/dashboard/products/:productId",
    exact: true,
    component: SingleProduct,
    isPremium: false,
  },

  {
    path: "/dashboard/upgrade-plan",
    exact: true,
    component: UpgradePlan,
    isPremium: false,
  },
  {
    path: "/dashboard/verify-payment",
    exact: true,
    component: VerifyPremiumPayment,
    isPremium: false,
  },
];


