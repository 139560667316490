import React, { useCallback, useState, useEffect } from "react";
import { CloseIcon } from "components/vectors";
import { useDispatch, useSelector } from "react-redux";
import InputField from "components/blocks/InputField";
import { trackEvent } from "libs/utils/customer-io";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import { MdOutlineFileUpload } from "react-icons/md";
import {raiseWalletIssue,raiseWalletIssueComplete} from "store/actions/orders";


const RaiseWalletIssue = ({ close, refnum,wallet_id}) => {
    const [files, setFiles] = useState(null);

    const { raisingWalletIssue, walletIssueComplete,walletIssueError} = useSelector(
      (state) => state.orders
    );

    const [imageError, setImageError] = useState(false);
    const fileTypes = ["png", "jpg", "jpeg"];
    const dispatch = useDispatch();
  
    const handleChange = useCallback(
      (files) => {
        if (files) {
          setFiles(files);
          console.log({ files });
          setImageError("");
        }
      },
      [files]
    );

    const removeFile = (index) => {
      const updatedFile = [...files];
      updatedFile.splice(index, 1);
      setFiles(updatedFile);
    };
  

   const IssuesSchema = yup.object().shape({
    ref: yup.string().required("Reference required"),
    issue: yup.string().required("Complain/issue required"),
  });


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(IssuesSchema),
    mode: "all",
    defaultValues: {
        ref: refnum, // Set your default value here
      },
  });

const handleUpload = (data) => {
    
    let formData = new FormData();
    formData.append("transaction_reference", data.ref);
    formData.append("dispute_description", data.issue);
    formData.append("wallet_id", wallet_id);
    if(files){
      formData.append(`dispute_proof`, files[0]);
    ;
    }
    dispatch(raiseWalletIssue(formData));

  };



  useEffect(() => {
    if (walletIssueComplete) {
      close();
      trackEvent("raise_wallet_issue")
      dispatch(raiseWalletIssueComplete());
    }
  }, [walletIssueComplete]);
  
 

  return (
    <div className="modal__component">
      <form onSubmit={handleSubmit(handleUpload)}>
        <div className="modal__title">
          <div className="d-flex justify-content-between">
            <p className="modal__header">
            Raise an issue for this transaction
            </p>
            <span>
              <CloseIcon onClick={close} />
            </span>
          </div>
        </div>
        <div className="modal__content modal__full">
        <div className="form__wrapper">
        {walletIssueError && <p className="text-danger small">{walletIssueError}</p>}
            <InputField
              require={true}
              defaultValue={refnum}
              id="ref"
              label="Reference ID"
              name="ref"
              placeHolder=""
              register={register}
              error={errors?.ref?.message}
              type="text"
            />
            
          </div> 

          
            <div className="form__wrapper">
            <InputField
              require={true}
              id="issue"
              label="Enter complain/issue"
              name="issue"
              placeHolder="Type Transaction complain/issue"
              register={register}
              error={errors?.issue?.message}
              type="textarea"
            />   
            </div>

            <label className="form__label">Upload transaction image(optional)</label>
                <section className="upload__wrapper">
                  <div
                    className={`form__wrapper border__wrapper ${
                      imageError ? "border-danger" : ""
                    }`}
                  >
                    <FileUploader
                      handleChange={handleChange}
                      multiple={true}
                      types={fileTypes}
                    >
                      <div className="file__uploader__wrapper ">
                        <MdOutlineFileUpload color="#5E60CE" size={30} />
                        <div>
                          <p className="title">
                            Drag and drop an transaction image, or{" "}
                            <span className="upload__button">Browse</span>
                          </p>
                          <p className="resolution__text">
                            High resolution image (png,jpg,gif)
                          </p>
                        </div>
                      </div>
                    </FileUploader>
                  </div>
                </section>
                <div className="selected__files">
                  {files ? (
                    <>
                      {Array.from(files).map((file, index) => (
                        <div className="file" key={index}>
                          <p>{file?.name}</p>
                          <span
                            className="remove__file"
                            onClick={() => removeFile(index)}
                          >
                            x
                          </span>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
                <div className="error__message">{imageError && imageError}</div>
              <br/>
            <button
            disabled={raisingWalletIssue}
             type="submit"
             className="button button--purple  px-2" 
           >
             Raise Issue
           </button>
           <br/>
      </div>
      </form>

    </div>
  );
};

export default RaiseWalletIssue;


