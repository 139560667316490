import { LoadingListItem } from "components/blocks";
import InputField from "components/blocks/InputField";
import DropdownAction from "components/dashboardComponent/DropdownAction";
import ModalComponent from "components/dashboardComponent/ModalComponent";
import Pagination from "components/dashboardComponent/Pagination";
import { SearchBar } from "components/dashboardComponent/SearchBar";
import { LinkIcon, ThreeDot, TrashIcon } from "components/vectors";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { invoiceStatus } from "libs/constants";
import { currencyFormatter, handleCopy } from "libs/utils";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  changeInvoiceStatus,
  getInvoices,
  requestPayment,
} from "store/actions/invoices";

import UploadInvoiceProof from "components/dashboardComponent/UploadInvoiceProof";
import DeleteInvoice from "components/dashboardComponent/DeleteInvoice";
import { appUrl } from "libs/constants/PepperestWebServices";

const Invoices = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const url = window.location.origin;
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [pageType, setPageType] = useState("");
  const [isCheck, setIsCheck] = useState([]);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [proofModal, setProofModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const {
    allInvoices,
    loading,
    error,
    updating,
    success,
    processing,
    actionCompleted,
  } = useSelector((state) => state.invoices);

  const invoiceSchema = yup.object().shape({
    action: yup.string().required("Invoice Status is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(invoiceSchema),
  });

  const loadInvoices = (page, pageType) => {
    const params = {
      page: page,
      query: query,
      tab: pageType,
    };
    dispatch(getInvoices(params));
  };

  useEffect(() => {
    loadInvoices(1, pageType);
  }, [pageType, query, success, actionCompleted, query]);

  let timeout = useRef();
  const handleSearch = (event) => {
    event.persist();
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setQuery(event.target.value);
    }, 2000);
  };

  const handleInvoiceStatusModal = (invoice) => {
    if (invoice?.status == "Pending" || invoice?.status == "Completed") return;
    setSelectedInvoice(invoice);
    setInvoiceModal((prev) => !prev);
  };

  const handleUploadProf = (invoice) => {
    setSelectedInvoice(invoice);
    setProofModal(true);
  };

  const handleDelete = (invoice) => {
    setSelectedInvoice(invoice);
    setDeleteModal(true);
  };

  const handleRequestPayment = (invoice) => {
    setSelectedInvoice(invoice);
    let params = {
      orderID: invoice.orderID,
    };
    dispatch(requestPayment(params));
  };

  const edit = (invoiceID) => {
    history.push(`/dashboard/invoices/${invoiceID}`);
  };

  const handleViewInvoice = (invoice) => {
    history.push(`/dashboard/invoices/detail/${invoice?.orderID}`);
  };

  const handleChangeInvoiceStatus = (data) => {
    dispatch(
      changeInvoiceStatus({
        orderID: selectedInvoice.orderID,
        action: data.action,
      })
    );
  };

  useEffect(() => {
    if (success) {
      setInvoiceModal(false);
      reset({
        action: "",
      });
    }
  }, [success]);

  return (
    <div className="invoices">
      <div className="title__wrapper">
        <p className="page__title"> Invoices</p>

        <Link to="/dashboard/invoices/new">
          <button className="button button--purple button__content">
            Create Invoice
          </button>
        </Link>
      </div>
      <div className="search__product__action__wrapper">
        <div className="search__wrapper">
          <SearchBar placeholder="Search in discount" onChange={handleSearch} />
        </div>
        <div className="actions__wrapper">
          <div
            className={`cursor__pointer ${
              isCheckAll || isCheck.length > 1 ? "" : "disabled"
            }`}
          >
            {" "}
            <TrashIcon width={16} height={17} /> Delete
          </div>
        </div>
      </div>
      <section className="invoice__types">
        {ivoiceTypes.map((invoice, index) => (
          <h4
            onClick={() =>
              setPageType(invoice.type == "All" ? "" : invoice.type)
            }
            className={`type ${
              invoice.type == pageType ||
              (pageType == "" && invoice.type == "All")
                ? "active__type"
                : ""
            }`}
            key={index}
          >
            {invoice.name}
          </h4>
        ))}
      </section>
      {loading ? (
        <div className="list-body">
          <ul>
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
          </ul>
        </div>
      ) : error ? (
        <div className="empty__state">
          <p>{error}</p>
        </div>
      ) : (
        <section className="table__wrapper">
          <table className="product__table table__large">
            <thead>
              <tr>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      // onClick={handleSelectAll}
                      className="form_input"
                    />
                    <span></span>
                  </label>
                </th>
                <th>Buyer's Name</th>
                <th>Description</th>
                <th>Due Date</th>
                <th>Cost</th>
                <th>Status</th>
                <th width="120px">Action</th>
              </tr>
            </thead>
            <tbody>
              {allInvoices?.data?.length > 0 ? (
                <>
                  {allInvoices?.data?.map((invoice, index) => (
                    <tr key={index}>
                      <td width={50}>
                        <label>
                          <input
                            type="checkbox"
                            // key={product.id}
                            // id={product.id}
                            // checked={isCheck.find((item) => item == product.id)}
                            // onChange={handleClick}
                            className="form_input"
                          />
                          <span></span>
                        </label>
                      </td>
                      <td width={250}> {invoice?.buyerName}</td>
                      <td>{invoice?.description}</td>
                      <td width={150}>{invoice?.date}</td>
                      <td width={150}>
                        {currencyFormatter.format(invoice?.cost)}
                      </td>
                      <td width={100}>
                        <span
                          className={`order__status ${
                            invoice?.status?.toLowerCase() == "in progress"
                              ? "status__in__progress d-block"
                              : `status__${invoice?.status?.toLowerCase()} d-block`
                          }`}
                        >
                          {invoice?.status}
                        </span>
                      </td>
                      <td style={{ width: "max-content" }}>
                        {pageType == "" || pageType == "Canceled" ? (
                          <DropdownAction title={<ThreeDot />} tabIndex={index}>
                            <li
                              className={`dropdown__item text__black ${
                                invoice?.status == "Pending" ||
                                invoice?.status == "Completed"
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => handleInvoiceStatusModal(invoice)}
                            >
                              Change status
                            </li>

                            <li
                              className="dropdown__item text__black"
                              onClick={() => edit(invoice?.orderID)}
                            >
                              Edit invoice
                            </li>
                            <li
                              className="dropdown__item text__black"
                              onClick={() =>
                                handleCopy(
                                  `${appUrl}/invoice?orderRef=${invoice?.orderRef}`
                                )
                              }
                            >
                              Copy link
                            </li>
                            <li
                              className={`dropdown__item text__black`}
                              onClick={() => handleViewInvoice(invoice)}
                            >
                              View Details
                            </li>
                          </DropdownAction>
                        ) : (
                          <div className="invoice__type__actions">
                            <LinkIcon color="#B0B0B0" onClick={handleCopy} />
                            <TrashIcon
                              onClick={() => handleDelete(invoice)}
                              width={16}
                              height={17}
                            />
                            {pageType === "Disputed" ? (
                              <button
                                className="upload__proof__button"
                                onClick={() => handleUploadProf(invoice)}
                              >
                                Upload Proof
                              </button>
                            ) : (
                              <button
                                className="request__payment__button "
                                disabled={
                                  processing &&
                                  selectedInvoice.orderID == invoice?.orderID
                                }
                                onClick={() => handleRequestPayment(invoice)}
                              >
                                Request Payment
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={7} className="text-center" align="center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={allInvoices?.meta?.current_page}
            totalCount={allInvoices?.meta?.total}
            pageSize={allInvoices?.meta?.per_page}
            onPageChange={(page) => loadInvoices(page)}
          />
        </section>
      )}

      <ModalComponent show={invoiceModal} centered size="md">
        <div className="modal__component">
          <div className="modal__title">Change Invoice status</div>
          <div className="modal__content ">
            <form
              onSubmit={handleSubmit(handleChangeInvoiceStatus)}
              className="col-md-12"
            >
              <section>
                <div className="form__wrapper">
                  <InputField
                    id="action"
                    label="Invoice Status"
                    name="action"
                    register={register}
                    options={invoiceStatus}
                    error={errors.action?.message}
                    type="select"
                  />
                </div>

                <div className="modal__control">
                  <button
                    type="button"
                    className="button button--purple-outline px-4"
                    onClick={() => setInvoiceModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button button--purple px-4"
                    type="submit"
                    disabled={updating}
                  >
                    Save Change
                  </button>
                </div>
              </section>
            </form>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent show={proofModal} centered size="md">
        <UploadInvoiceProof
          setProofModal={setProofModal}
          selectedInvoice={selectedInvoice}
        />
      </ModalComponent>
      <DeleteInvoice
        deleteModal={deleteModal}
        selectedInvoice={selectedInvoice}
        setDeleteModal={setDeleteModal}
      />
    </div>
  );
};

export default Invoices;

const ivoiceTypes = [
  { type: "All", name: "All Invoices" },
  { type: "Due", name: "Due Invoices" },
  { type: "Canceled", name: "Cancelled Invoices" },
  { type: "Disputed", name: "Disputed Invoices" },
];
