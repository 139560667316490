import React, { lazy } from "react";
import "../buyer-assets/css/index.css";



const Category = lazy(() => import("./category"));
const ProductsCanonical = lazy(() => import("./products-canonical"));


export {
  Category,
  ProductsCanonical,
 
};
