import React from "react";
import ReactDOM from "react-dom";
import { render } from "react-snapshot";
import "./index.css";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import authReducer from "./store/reducers/auth";
import dashboardReducer from "./store/reducers/dashboard";
import paymentsReducer from "./store/reducers/payments";
import productsReducer from "./store/reducers/products";
import customersReducer from "./store/reducers/customers";
import walletReducer from "./store/reducers/wallet";
import ordersReducer from "./store/reducers/orders";
import invoicesReducer from "./store/reducers/invoices";
import SingleInvoicesReducer from "./store/reducers/singleInvoice";
import userAccountReducer from "./store/reducers/userAccount";
import cartReducer from "./store/reducers/cart";
import merchantReducer from "./store/reducers/merchant";
import marketReducer from "./store/reducers/marketplace";
import alertReducer from "./store/reducers/alert";
import messagingReducer from "./store/reducers/messaging";
import { ShoppingCartProvider } from "context/ShoppingCartContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ActiveStateContextProvider } from "context/activeStateContext";
import bugsnagClient from "./libs/utils/bugsnag";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const ErrorBoundary = bugsnagClient.getPlugin('react');

const rootReducer = combineReducers({
  auth: authReducer,
  payments: paymentsReducer,
  products: productsReducer,
  customers: customersReducer,
  orders: ordersReducer,
  invoice: SingleInvoicesReducer,
  invoices: invoicesReducer,
  userAccount: userAccountReducer,
  cart: cartReducer,
  merchant: merchantReducer,
  alert: alertReducer,
  marketPlace:marketReducer,
  dashboard:dashboardReducer,
  wallets:walletReducer,

});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const queryClient = new QueryClient();

const app = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
    
        <ActiveStateContextProvider>
          <App />
        </ActiveStateContextProvider>
     
    </QueryClientProvider>
  </Provider>
);
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(app, rootElement);
} else {
  ReactDOM.render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
