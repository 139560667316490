import React, { useCallback, useState, useEffect } from "react";
import { CloseIcon } from "components/vectors";
import { useDispatch, useSelector } from "react-redux";
import InputField from "components/blocks/InputField";
import { trackEvent } from "libs/utils/customer-io";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { createWallet } from "store/actions/wallet";


const CreateWallet = ({ close, type}) => {
    
    const { creatingWallet, createdWallet,createWalletError} = useSelector(
      (state) => state.wallets
    );

    const dispatch = useDispatch();

  

   const IssuesSchema = yup.object().shape({
    bvn: yup.string().required("BVN is required"),
    date_of_birth: yup.string().required("Date of birth required"),
  });


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(IssuesSchema),
    mode: "all",
    defaultValues: {
        bvn: "",
        date_of_birth:"" // Set your default value here
      },
  });

const create_wallet = (data) => {
    
const params =   {
    provider : type,
    bvn : data.bvn,
    date_of_birth : data.date_of_birth
}

dispatch(createWallet(params));

  };



  useEffect(() => {
    if (createdWallet) {
     reset({
        bvn: "",
        date_of_birth:""
     })
      close();
      window.location.reload()
    }
  }, [createdWallet]);
  

  return (
    <div className="modal__component">
      <form onSubmit={handleSubmit(create_wallet)}>
        <div className="modal__title">
          <div className="d-flex justify-content-between">
            <p className="modal__header">
             Create {type} wallet account
            </p>
            <span>
              <CloseIcon onClick={close} />
            </span>
          </div>
        </div>
        <div className="modal__content modal__full">
        {createWalletError && <p className="text-danger small">{createWalletError}</p>}
        <div className="form__wrapper">
            <InputField
              require={true}
              defaultValue={""}
              id="bvn"
              label="Enter your Bvn"
              name="bvn"
              placeHolder="Enter your BVN number"
              register={register}
              error={errors?.bvn?.message}
              type="text"
            />
            
          </div> 

          <div className="form__wrapper">
            <InputField
              require={true}
              defaultValue={""}
              id="date_of_birth"
              label="Select Date of Birth"
              name="date_of_birth"
              placeHolder=""
              register={register}
              error={errors?.date_of_birth?.message}
              type="date"
            />
            
          </div>

              <br/>
            <button
            disabled={creatingWallet}
             type="submit"
             className="button button--purple  px-2" 
           >
             Create Wallet
           </button>
           <br/>
      </div>
      </form>

    </div>
  );
};

export default CreateWallet;


