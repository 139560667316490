import { CloseIcon } from "components/vectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  getWalletHistory
} from "store/actions/wallet";

import { currencyFormatter } from "libs/utils";
import { trackEvent } from "libs/utils/customer-io";

const ViewWalletHistory = ({ close,setRaiseIssue,setRef,wallet_id}) => {
    
  const [activeTab, setActiveTab] = useState('all');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const dispatch = useDispatch();

   const {
     walletHistory,
     loadingWalletHistory
   } = useSelector((state) => state.wallets);

  useEffect(()=>{
    dispatch(getWalletHistory({type:activeTab,wallet_id}));
  },[activeTab,wallet_id])

  useEffect(()=>{
    if(walletHistory){
      trackEvent("wallet_history")
    }
  },[])
  


  return (
    <div className="modal__component">
      
        <div className="modal__title">
          <div className="d-flex justify-content-between">
            <p className="modal__header">
            Wallet History
            </p>
            <span>
              <CloseIcon onClick={close} />
            </span>
          </div>
        </div>
        <div className="modal__content modal__full">
        <div className="tab-header">
                  <p onClick={() => handleTabChange('all')} className={activeTab === 'all' ? 'tab-active' : 'non-active'}>
                    All
                  </p>
                  <p onClick={() => handleTabChange('debit')} className={activeTab === 'debit' ? 'tab-active' : 'non-active'}>
                    Debit
                  </p>
                  <p onClick={() => handleTabChange('credit')} className={activeTab === 'credit' ? 'tab-active' : 'non-active'}>
                   Credit
                  </p>
              </div>

              <div className="tab-content">
                  {activeTab === 'all' && <div className="transaction-box">
                    <p className="headtitle">All Transactions</p>
                    {loadingWalletHistory ? <p>Loading history ...</p> :<>

                      {walletHistory?.data?.length > 0 ? 
                        (
                          walletHistory?.data?.map((wallet, index) => (
                            <div key={index} className="transaction">
                              {wallet?.hasDispute === false &&
                              <button onClick={() => {close(); setRaiseIssue(true); setRef(wallet?.transaction_ref)}} className="raise-issue">Raise Dispute</button>
                                }
                               <div className="datawrapper">
                                <p className="title">Ref: </p>
                                <p className="data">{wallet?.transaction_ref}</p>
                                </div>
                              <div className="datawrapper">
                              <p className="title">Amount: </p>
                              <p className="data">{currencyFormatter.format(wallet?.amount)}</p>
                              </div>
                              <div className="datawrapper">
                              <p className="title">Narration:</p>
                              <p className="data">{wallet.narration}</p>
                              </div >
                              <div className="datawrapper">
                              <p className="title">Status:</p>
                              <p className="data"> {wallet.status}</p>
                              </div>
                              <div className="datawrapper">
                              <p className="title">date: </p>
                              <p className="data">{wallet.created_at}</p>
                              </div>
                              </div>
                          ))
                        ):(
                          <p className="no-transaction">No transaction found</p>
                        )}
                      </>}
                    
                     </div>}
                  {activeTab === 'debit' && <div className="transaction-box">
                  <p className="headtitle">Debit Transactions</p>
                  {loadingWalletHistory ? <p>Loading history ...</p> :<>
                  
                      {walletHistory?.data?.length > 0 ? 
                        (
                          walletHistory?.data?.map((wallet, index) => (
                            <div key={index} className="transaction">
                              {wallet?.hasDispute === false &&
                              <button onClick={() => {close(); setRaiseIssue(true); setRef(wallet?.transaction_ref)}} className="raise-issue">Raise Dispute</button>
                                }
                               <div className="datawrapper">
                                <p className="title">Ref: </p>
                                <p className="data">{wallet?.transaction_ref}</p>
                                </div>
                                <div className="datawrapper">
                              <p className="title">Recipient name: </p>
                              <p className="data">{wallet.to_account_name}</p>
                              </div>
                              <div className="datawrapper">
                              <p className="title">Recipient bank: </p>
                              <p className="data">{wallet.to_bank_name}</p>
                              </div>
                              <div className="datawrapper">
                              <p className="title">Recipient acct_no: </p>
                              <p className="data">{wallet.to_account_no}</p>
                              </div>
                              <div className="datawrapper">
                              <p className="title">Amount: </p>
                              <p className="debit">-{currencyFormatter.format(wallet?.amount)}</p>
                            
                              </div>
                              <div className="datawrapper">
                              <p className="title">Narration:</p>
                              <p className="data">{wallet.narration}</p>
                              </div>
                             
                              <div className="datawrapper">
                              <p className="title">Status:</p>
                              <p className="data"> {wallet.status}</p>
                              
                              </div>
                              <div className="datawrapper">
                          
                              <p className="title">date: </p>
                              <p className="data">{wallet.created_at}</p>
                              </div>
                              
                              </div>
                          ))
                        ):(
                         <p className="no-transaction">No Debit transaction found</p> 
                        )}
                      </>}
                    
                    </div>}

                    {activeTab === 'credit' && <div className="transaction-box">
                    <p className="headtitle">Credit Transactions</p>
                    {loadingWalletHistory ? <p>Loading history ...</p> :<>

                        {walletHistory?.data?.length > 0 ? 
                          (
                            walletHistory?.data?.map((wallet, index) => (
                              <div key={index} className="transaction">
                                {wallet?.hasDispute === false &&
                              <button onClick={() => {close(); setRaiseIssue(true); setRef(wallet?.transaction_ref)}} className="raise-issue">Raise Dispute</button>
                                }
                                <div className="datawrapper">
                                <p className="title">Ref: </p>
                                <p className="data">{wallet?.transaction_ref}</p>
                                </div>
                                <div className="datawrapper">
                                <p className="title">Sender: </p>
                                <p className="data">{wallet?.from_account_name}</p>
                                </div>
                                <div className="datawrapper">
                                <p className="title">Bank: </p>
                                <p className="data">{wallet?.from_bank_name}</p>
                                </div>
                                <div className="datawrapper">
                                <p className="title">Account: </p>
                                <p className="data">{wallet?.from_account_no}</p>
                                </div>
                                <div className="datawrapper">
                                <p className="title">Amount: </p>
                                <p className="data text-success font-weight-bold">{currencyFormatter.format(wallet?.amount)}</p>
                                </div>
                                <div className="datawrapper">
                                <p className="title">Narration:</p>
                                <p className="data">{wallet?.narration}</p>
                                </div >
                                <div className="datawrapper">
                                <p className="title">Status:</p>
                                <p className="data"> {wallet?.status}</p>
                                </div>
                                <div className="datawrapper">
                                <p className="title">date: </p>
                                <p className="data">{wallet?.created_at}</p>
                                </div>
                                </div>
                            ))
                          ):(
                            <p className="no-transaction">No Credit transaction found</p>
                          )}
                        </>}
                    </div>}
                  
                </div>
            

            
          </div>    

     
    </div>
  );
};

export default ViewWalletHistory;


