
import React,{useState} from 'react'
import { Link } from "react-router-dom";
import { EyesWalletClose,EyesWalletOpen } from "components/vectors";
import { currencyFormatter } from 'libs/utils';


function WalletCard({wallet,handleCopy,userProfile,setWithdraw,withdraw,setLoadWalletHistory,name,showCreateWallet}) {
    const [activeTab, setActiveTab] = useState("tab1");
    const [seeBalance, setSeeBalance] = useState(false);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
      };
      
  return (
    <div style={{backgroundColor: name == "mozfin" ? "#219653" : "#0c1ff4" }} className="walletbox1">
      { wallet ? <>

        <div className="tab-header">
  <p
    onClick={() => handleTabChange("tab1")}
    className={
      activeTab === "tab1" ? "active" : "nonactive"
    }
  >
    My Wallet
  </p>
  <p
    onClick={() => handleTabChange("tab2")}
    className={
      activeTab === "tab2" ? "active" : "nonactive"
    }
  >
    Wallet Details
  </p>
  <Link
    className= "nonactive"
    to="/dashboard/store-setting"
  >
 {userProfile?.hasTransactionPin ? "Reset PIN":"Set PIN"} 
  </Link>
</div>
<div className="tab-content">
  {activeTab === "tab1" && (
    <div className="mywallet">
      <p className="wallet-balance">Wallet Balance</p>
      <p className="total-balance" onClick={()=>setSeeBalance(prev=>!prev)}>
        {seeBalance ? 
        <>{currencyFormatter.format(wallet?.amount)}<span style={{}} > 
        <EyesWalletClose /> </span></> : <>**** <span ><EyesWalletOpen /> </span></>}

      </p>
      
      <button
        className="withdraw-button"
        onClick={() => setWithdraw(!withdraw)}
      >
        Withdraw
      </button>
    </div>
  )}
  {activeTab === "tab2" && (
    <div className="wallet-details">
      <p className="bank">{wallet?.bank_name}</p>
      <p className="unique-id">
        {wallet?.account_number}
        <img
          src="/assets/images/copy.png"
          className="copy"
          onClick={() =>
            handleCopy(`${wallet?.account_number}`)
          }
        />
      </p>
      <p className="name">
        Name: {userProfile?.firstName}{" "}
        {userProfile?.lastName}
      </p>
      <p
        className="view-history"
        onClick={() => setLoadWalletHistory(true)}
      >
        View History
      </p>
    </div>
  )}
</div>
 <p className='powered'>Powered by {name.toUpperCase()}</p> 

      </>:
      <div className='no-account'>
        <p className='text'>No account found</p>
       <button onClick={showCreateWallet} className='no-account-button'>
         Create {name} wallet account
      </button>
      </div>
      }

</div>
  )
}

export default WalletCard


